<template>
	<div class="wrap" v-loading.fullscreen.lock="fullscreenLoading">
		<top :bgWhite='true'></top>
		<div class="contentAll_detail  padding-lr-100  top-80 padding-bottom-50 flex">
			<!-- 左侧操作 -->
			<div class="handle-list margin-right-50">
				<div class="handle-item flex align-center justify-center flex-direction margin-bottom-10 point" @click="showSection = true">
					<img src="../../assets/image/book/log.png" class="block-24">
					<p class="fs9">目录</p>
				</div>
				<div class="handle-item flex align-center justify-center flex-direction margin-bottom-10 point" v-show="is_buy==0 && is_free==0"  @click="toBuy">
					<img src="../../assets/image/book/fee.png" class="block-24">
					<p class="fs9">购买</p>
				</div>
				<div class="handle-item flex align-center justify-center flex-direction margin-bottom-10 point" @click="goAnchor('#comments')">
					<img src="../../assets/image/book/msg.png" class="block-24">
					<p class="fs9">评论</p>
					<div class="msg-box">
						{{totalSize || 0}}
					</div>
				</div>
				<div class="handle-item flex align-center justify-center flex-direction margin-bottom-10 point set_btn" @click="showSetModal = !showSetModal">
					<img src="../../assets/image/book/set.png" class="block-24">
					<p class="fs9">设置</p>
				</div>
				<!-- 设置弹窗 -->
				<div >
					<transition name="el-fade-in">
						<div class="" id="set-modal" v-show="showSetModal">
							<div class="text-white flex align-center justify-around padding-tb-20 title-box">
								<p></p>
								<p class="title">设置</p>
								<p class="fs26 close-icon el-icon-close" @click="showSetModal = !showSetModal"></p>
							</div>
							<div class="padding-lr-20 flex flex-direction justify-around " style="height: 78%;">
								<!-- 亮度 -->
								<div class="flex align-center">
									<span class="text-white margin-right-20">亮度</span>
									<img src="../../assets/image/icon/sun-small.png" class="block-24">
									<div class="padding-lr-20 " style="width: 60%;">
										<el-slider v-model="range" input-size="mini"></el-slider>
									</div>
									<img src="../../assets/image/icon/sun-big.png" class="block-24">
								</div>
								<!-- 字号 -->
								<div class="flex align-center">
									<span class="text-white margin-right-20">字号</span>
									<div class="flex align-center justify-start " style="width: 60%;">
										<div class="font-box flex align-center justify-center point" @click="fontReduce">
											<img src="../../assets/image/icon/font-small.png" class="block-15">
										</div>
										<p class="text-white margin-lr-50">{{fontSize}}</p>
										<div class="font-box flex align-center justify-center point" @click="fontAdd">
											<img src="../../assets/image/icon/font-big.png" class="block-15">
										</div>
									</div>
								</div>
								<!-- 背景 -->
								<div class="flex align-center">
									<span class="text-white margin-right-20 fs9">背景</span>
									<div class="flex align-center  " style="width: 85%;">
										<div class="block-32 radius-32 flex align-center justify-center point margin-right-20"
											@click="chooseBg(item,index)" v-for="(item,index) in colorLists" :key="index"
											:style="{background:item.bgColor}">
											<i class="el-icon-check fs26 bold" v-if="colorIndex==index"
												:class="colorIndex==index ? 'select_color' : '' "></i>
										</div>
									</div>
								</div>
								<!-- 按钮 -->
								<div class="flex align-center justify-around">
									<el-button type="info" round size="mini" @click="reset">恢复设置</el-button>
									<el-button type="danger" round size="mini" @click="saveSet">保存设置</el-button>
								</div>
							</div>
						</div>
					 </transition>
				</div>
			</div>
			<!-- 右侧文章 -->
			<div class="right-box" id="target">
				<!-- 上部内容 -->
				<div class="top-content padding-lr-30 padding-bottom-40" :style="[{background:bgColor}]">
					<div class="flex align-center justify-center flex-direction padding-top-sm">
						<div class="title">
							<p class="margin-lr-10">{{title}}</p>
						</div>
						<div class="margin-top-20">
							<!-- <span class="fs9">作者：{{user.user_nickname}} 最新章节：{{newCap}}</span> -->
							<span class="fs9">作者：{{user.user_nickname}} </span>
						</div>
						<div class="margin-top-30" v-if="is_buy==1 || is_free==1">
							<p class="aContent" :style="{fontSize:fontSize+'px'}" v-html="body"></p>
						</div>
					</div>
					<!-- 收费内容 -->
					<div class="flex flex-direction align-center justify-around fee-box padding-tb-30 margin-top-20" v-if="is_buy==0 && is_free==0">
						<p class="fs9 text-blue">抱歉哦！本电子书为付费电子书，需要购买后才能阅读…</p>
						<p class="fs9 text-red margin-tb-20">价格：￥{{data.money || '0.00'}}</p>
						<el-button type="danger" round @click="toBuy">购买电子书</el-button>
					</div>
					<!-- 版权说明 -->
					<div class="copyright fs9 margin-top-50">
						保护作者权益，我们一起支持正版
					</div>
					<!-- 翻页操作 -->
					<div class="page_handle margin-top-40 flex align-center justify-around">
						<el-button type="danger" plain round @click="pageClick(1)" v-loading.fullscreen.lock="fullscreenLoading">上一章</el-button>
						<el-button type="info" plain round @click="showSection = true" >目录</el-button>
						<el-button type="danger" plain round @click="pageClick(2)" v-loading.fullscreen.lock="fullscreenLoading">下一章</el-button>
					</div>
				</div>
				<!-- 底部评论 -->
				<div class="margin-top-50 comment-box padding-tb-30" id="comments">
					<!-- 评价模块 -->
					<div class="padding-lr-sm">
						<div class="">
							<div class="">
								<div class="eva_title">
									<span class="fs16 bold margin-right-5">评价</span>
									<span class="fs14">{{totalSize}}</span>
								</div>
								<div class="flex align-center justify-between margin-tb-20">
									<div class="flex align-center">
										<img :src="userInfo.head_img" class="block-48 margin-right-10 radius-48">
										<div class="input-box flex align-center padding-lr-xs justify-between">
											<input type="" v-model="contents" name="" id="" value="" placeholder="写评价…" />
											<img src="../../assets/image/icon/smile.png" class="block-28">
										</div>
									</div>
									<div class="fs12">
										<el-button type="danger"  round size="mini" @click="postComment">评价</el-button>
									</div>
								</div>
							</div>
						</div>
						<div class="">
							<div v-for="(item,index) in lists" :key="index" class="margin-bottom-22">
								<evaluate-item :info="item" :can_reply="false"></evaluate-item>
							</div>
						</div>
					</div>
					<!-- 评价模块 end -->
				</div>
			</div>
		</div>
		<bottom></bottom>
		<!-- 悬浮按钮 -->
		<div class="float-box flex flex-direction align-center justify-around" v-show="scrollTop>500">
			<div class="img-box flex align-center justify-center">
				<img src="../../assets/image/home/top.png" class="block-19" @click="scrollToTop">
			</div>
			<div class="img-box flex align-center justify-center" @click="refresh">
				<img src="../../assets/image/home/uptade_black.png" :class="showRotate ? 'loading' : '' " class="block-19">
			</div>
		</div>
		<!-- 目录弹窗 -->
		<el-dialog :title="data.title" :visible.sync="showSection">
		  <section-modal :aid="document_id" @itemClick="itemClick" :is_buy="is_buy" :is_free="is_free"></section-modal>
		</el-dialog>

	</div>
</template>

<script>
	import sectionModal from '@/components/section/index.vue';
	import evaluateItem from '@/components/evaluate/evalaute.vue';
	import top from '@/components/top';
	import bottom from '@/components/bottom';
	export default {
		components: {
			evaluateItem,
			top,
			bottom,
			sectionModal
		},
		data() {
			return {
				capterLists:[], //章节列表
				fullscreenLoading:true,
				showRotate:false,
				loading:false,
				page:1,
				body:'',//正文
				showSection:false, //显示目录弹窗
				showSetModal:false, //是否显示设置弹窗
				contents:'', //评价内容
				is_buy:0, //是否购买
				is_free:0, //是否免费
				totalSize:0,
				range: 100, //亮度
				fontSize: localStorage.getItem('fontSize') || 12,
				bgColor: localStorage.getItem('bgColor') || '#ffffff',
				colorIndex: 0,
				scrollTop: 0,
				colorLists: [{
						bgColor: '#ffffff'
					},
					{
						bgColor: '#FAADA7'
					},
					{
						bgColor: '#DAE6C5'
					},
					{
						bgColor: '#E7E1C6'
					},
					{
						bgColor: '#F9FAF9'
					},
					{
						bgColor: '#3A3F44'
					},
					{
						bgColor: '#463D3D'
					}
				],
				lists: [],
				document_id:'' ,//电子书id
				user:{}, //作者信息
				data:{} ,//详情信息
				newCap:'' ,//最新章节
				title:'' ,//章节标题
				section_id:'' ,//章节id
			}
		},
		mounted() {
			window.addEventListener('scroll', this.handleScroll);
			this.title = this.$route.query.title;
			this.newCap = this.$route.query.newSection;
			this.section_id = this.$route.query.id;
			this.document_id = this.$route.query.document_id;
			this.getSectionDetail(this.section_id) //章节详情
			this.getBookDetail()//
			this.getLogLists() //获取章节列表;
			this.getCommentLists() //获取评论列表
		},
		computed: {
			opticy() {
				return 100 - this.range
			},
			userInfo() {
				return this.$store.getters.userInfo;
			}
		},
		destroyed() {
			window.removeEventListener('scroll', this.handleScroll);
		},
		methods: {
			refresh(){
				this.showRotate = true;
				setTimeout(()=>{
					this.showRotate = false
				},400);
				this.getBookDetail()
			},
			// 章节点击
			itemClick(e){
				this.title = e.title;
				this.section_id = e.id;
				this.getSectionDetail(this.section_id);
				this.showSection = false;
			},
			// 评论锚点
			goAnchor(selector) {
			      document.querySelector(selector).scrollIntoView({behavior: "smooth", block: "start", inline: "nearest"});
			},
			// 页数
			async pageClick(type){
				let chapter_index  =  await this.matchChapterIndex();
				switch (type){
					// 上一章
					case 1:
						if(chapter_index==0){
							this.$message.info('已经是第一章了')
							return;
						};
						this.getSectionDetail(this.capterLists[chapter_index-1].id);
						this.title = this.capterLists[chapter_index-1].title;
						this.section_id = this.capterLists[chapter_index-1].id;
						target.scrollIntoView();
						break
					// 下一章
					case 2:
						if(chapter_index==this.capterLists.length-1){
							this.$message.info('已经是最后一章了')
							return;
						}
						this.getSectionDetail(this.capterLists[chapter_index+1].id)
						this.title = this.capterLists[chapter_index+1].title;
						this.section_id = this.capterLists[chapter_index+1].id;
						target.scrollIntoView();
						break
					default:
						break;
				}
			},
			// 匹配章节索引
			matchChapterIndex() {
				return new Promise((resolve,reject)=>{
					this.ajax('post', '/v1/61c6b084561cd', {
						page:this.page,
						document_id:this.document_id
					}, res => {
						if (res.code == 1) {
							let lists = res.data;
							let index = lists.findIndex(item=>{
								return item.id==this.section_id
							});
							resolve(index)
						} else {
							this.$message.error(res.msg);
						}
					
					}, err => {
						console.log(err);
					})
				})
			},
			// 获取章节列表
			getLogLists() {
				this.ajax('post', '/v1/61c6b084561cd', {
					page:this.page,
					document_id:this.document_id
				}, res => {
					this.fullscreenLoading = false;
					if (res.code == 1) {
						this.capterLists = res.data;
					} else {
						this.$message.error(res.msg);
					}
				
				}, err => {
					console.log(err);
				})
			},
			// 获取电子书详情
			getBookDetail() {
				this.ajax('post', '/v1/613afc809f5e3', {
					article_id: this.document_id
				}, res => {
					if (res.code == 1) {
						this.user = res.data.user;
						this.data = res.data;
						this.is_buy = res.data.is_buy;
						this.is_free = res.data.is_free;
					} else {
						this.$message.error(res.msg);
					}
				})
			},
			// 获取章节详情
			getSectionDetail(section_id) {
				this.ajax('post', '/v1/Document/getDocumentBody', {
					id: section_id
				}, res => {
					this.fullscreenLoading = false;
					if (res.code == 1) {
						this.body = res.data.body;
					} else {
						this.$message.error(res.msg);
					}
				})
			},
			// 提交评论
			postComment() {
				if(!this.contents.trim()){
					this.$message.error('请先输入评论内容');
					return false;
				}
				this.ajax('post', '/v1/6151764c066ea', {
					document_id: this.document_id,
					content:this.contents
					// id:'' //回复谁传谁的id
				}, res => {
					if (res.code == 1) {
						this.$message.success(res.msg);
						this.contents = '';
						this.status = 'more';
						this.getCommentLists();
					} else {
						this.$message.error(res.msg);
					}
				})
			},
			// 获取评论列表
			getCommentLists() {
				this.ajax('post', '/v1/61516d9c4dc60', {
					page:this.page,
					document_id:this.document_id,
					id:'' //回复谁传谁的id
				}, res => {
					this.isInit = true;
					if (res.code == 1) {
						this.totalSize = res.data.total;
						this.lists =  res.data.data;
					} else {
						this.$message.error(res.msg);
					}
				
				}, err => {
					console.log(err);
					this.hadGotCode = false
				})
				
			},
			// 购买电子书
			toBuy() {
				this.ajax('post', '/v1/5d784b976769e', {
					order_type:6, //1 ：'现金充值'，2：'虚拟币充值'，3 ：'商城交易'，4：认证支付5：打赏6：文章购买
					document_id: this.document_id
				}, res => {
					if (res.code == 1) {
						this.$router.push({
							path: '/payMoney',
							query:{
								order_sn:res.data.order_sn,
								order_money:res.data.money,
								return_url:JSON.stringify(window.location.href)
							}
						})
					} else {
						this.$message.error(res.msg);
					}
				})
			},
			// 恢复设置
			reset() {
				this.fontSize = 12;
				this.bgColor = '#ffffff';
				localStorage.removeItem('fontSize');
				localStorage.removeItem('bgColor');
				this.showSetModal = !this.showSetModal
			},
			// 保存设置
			saveSet(){
				localStorage.setItem('fontSize',this.fontSize);
				localStorage.setItem('bgColor',this.bgColor);
				this.showSetModal = !this.showSetModal
			},
			fontReduce() {
				if (this.fontSize <= 12){
					this.$message.info('已经是最小啦');
					return;
				} 
				this.fontSize--;
			},
			fontAdd() {
				if (this.fontSize >= 20){
					this.$message.info('已经是最大啦');
					return;
				}
				this.fontSize++;
				console.log(this.fontSize);
			},
			// 选择背景色
			chooseBg(item, index) {
				this.colorIndex = index;
				this.bgColor = item.bgColor;
			},
			handleScroll(e) {
				this.scrollTop = e.target.documentElement.scrollTop || e.target.body.scrollTop; // 执行代码
			},
			// 显示按钮的显示与隐藏
			scrollToTop() {
				let scrollToptimer = setInterval(function() {
					// console.log("定时循环回到顶部");
					var top = document.body.scrollTop || document.documentElement.scrollTop;
					var speed = top / 4;
					document.documentElement.scrollTop -= speed;
					if (top == 0) {
						clearInterval(scrollToptimer);
					}
				}, 30);
			}
		}
	}
</script>

<style lang="scss">
	body,html{
		background: #F6F7F9 !important;
	}
	.contentAll_detail {
		width: 50%;
		position: relative;
		margin: 0 auto;
	}
	.loading {
	     -webkit-animation: rotation 0.4s linear; 
	} 
	@-webkit-keyframes rotation { 
	     from { 
	       -webkit-transform: rotate(0deg); 
	     } 
	     to { 
	       -webkit-transform: rotate(180deg); 
	     } 
	} 
	.el-icon-check {
		color: #E54F42;
	}

	.el-slider__bar {
		background: #E54F42 !important;
	}

	.fee-box {
		width: 100%;
		background: rgba(62, 98, 177, 0.04);
	}

	.text-blue {
		color: #3E62B1;
	}

	.text-red {
		color: #E54F42;
	}
	.handle-list {
		position: relative;
		z-index: 8;
		// &:hover #set-modal {
		// 	display: block;
		// }
		.handle-item {
			width: 64px;
			height: 64px;
			background: #FFFFFF;
			box-shadow: 0px 2px 11px rgba(0, 0, 0, 0.08);
			opacity: 1;
			border-radius: 5px;
			&:nth-child(4):hover #set-modal {
				display: block;
			}
			&:hover {
				color: #F78989;
			}
			&:nth-child(3){
				position: relative;
				.msg-box{
					position: absolute;
					text-align: center;
					top: 0;
					right: -14px;
					width: 32px;
					height: 22px;
					padding: 1px;
					background: #E54F42;
					opacity: 1;
					font-size: 12px;
					border-radius: 9px 9px 9px 0px;
					color: #FFFFFF;
				}
			}
		}

		#set-modal {
			position: absolute;
			z-index: 999999;
			left: 65px;
			top: 220px;
			width: 476px;
			height: 352px;
			background: #333333;
			border-radius: 8px;

			.title-box {
				position: relative;

				.title {
					margin: 0 auto;
				}

				.close-icon {
					position: absolute;
					right: 20px;
				}
			}

			.font-box {
				width: 66px;
				height: 24px;
				background: #4F4F4F;
				opacity: 1;
				border-radius: 64px;
			}
		}

	}

	.right-box {
		width: 100%;
		opacity: 1;
	}

	.comment-box {
		width: 100%;
		margin-right: 100px;
		background: #FFFFFF;
		opacity: 1;
	}

	.eva_title {
		position: relative;
		z-index: 99;

		&:after {
			content: '';
			position: absolute;
			left: 0;
			bottom: 0;
			width: 75px;
			height: 7px;
			background: linear-gradient(89deg, rgba(229, 79, 66, 0.7) 0%, rgba(255, 255, 255, 0) 100%);
			opacity: 1;
			z-index: 2
		}
	}

	.input-box {
		width: 666px;
		height: 48px;
		background: #F6F7F9;
		opacity: 1;
		border-radius: 5px;

		input {
			width: 100%;
			background: #F6F7F9;
		}
	}

	.right-box {
		.top-content {
			background: rgba(0, 0, 0, 1);

			.title {
				height: 21px;
				font-size: 16px;
				font-family: Microsoft YaHei;
				font-weight: bold;
				line-height: 27px;
				color: #333333;
				opacity: 1;
			}

			.aContent {
				font-size: 11px;
				font-family: Microsoft YaHei;
				font-weight: 400;
				line-height: 37px;
				color: #000000;
				opacity: 1;
			}

			.copyright {
				font-size: 9px;
				font-family: PingFang SC;
				font-weight: 400;
				line-height: 27px;
				color: #666666;
				opacity: 1;
				text-align: center;
				position: relative;

				&:before {
					position: absolute;
					content: '';
					width: 180px;
					left: 0;
					top: 12px;
					height: 1px;
					border-bottom: 1px dashed #C0C4CC;
					opacity: 1;
				}

				&:after {
					position: absolute;
					content: '';
					width: 180px;
					right: 0;
					top: 12px;
					height: 1px;
					border-bottom: 1px dashed #C0C4CC;
					opacity: 1;
				}
			}
		}
	}

	.float-box {
		position: fixed;
		right: 120px;
		bottom: 138px;
		width: 48px;
		height: 164px;
		opacity: 1;
		border-radius: 43px;

		.img-box {
			width: 46px;
			height: 46px;
			border-radius: 50%;
			background: #FFFFFF;
			box-shadow: 0px 2px 11px rgba(0, 0, 0, 0.08);
		}
	}
</style>
