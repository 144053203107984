<template>
	<div class="log_box">
		<div class="log_list ">
			<div class=" padding-lr-20" >
				<el-row :gutter="24">
					<el-col :span="8" class="list_item" v-for="(item,index) in lists" :key="index">
						<div class="flex align-center point" @click="sectionClick(item)">
							<p class="fs9">{{item.title}}</p>
							<img src="../../assets/image/fee.png" class="block-18 margin-left-5" v-if="is_buy==0 && is_free==0">
						</div>
					</el-col>
				</el-row>
				<!-- 分页 -->
				<!-- <div class="flex align-center justify-center">
					<el-pagination background  layout="prev, pager, next" :total="totalSize" :page-size='15' :hide-on-single-page='true' @current-change="currentChange">
					</el-pagination>
				</div> -->
				<!-- 分页 end-->
			</div>
		</div>
	</div>
</template>

<script>
	export default {
		data(){
			return{
				page:1, //页数
				totalSize:0, //分页总数
				order:'asc', //排序方式
				lists:[]
			}
		},
		props:{
			aid:{
				type:[String,Number],
				default:''
			},
			is_buy:{
				type:[String,Number],
				default:0
			},
			is_free:{
				type:[String,Number],
				default:0
			}
		},
		created() {
			this.getLogLists()
		},
		methods:{
			// 获取章节列表
			getLogLists() {
				this.ajax('post', '/v1/61c6b084561cd', {
					page:this.page,
					document_id:this.aid,
					order:this.order
				}, res => {
					if (res.code == 1) {
						this.totalSize = res.data.length;
						this.lists =  res.data;
					} else {
						this.$message.error(res.msg);
					}
				
				}, err => {
					console.log(err);
					this.hadGotCode = false
				})
				
			},
			// 点击当前页
			currentChange(e){
				this.page = e;
				this.getLogLists()
			},
			// 章节点击事件
			sectionClick(item){
				this.$emit('itemClick',item)
			}
		}
	}
</script>

<style scoped lang="scss">
.log_box{
	.log_title{
		width: 100%;
		height: 48px;
		background: #F6F7F9;
		border: 1px solid #F6F7F9;
		opacity: 1;
	}
	.log_list{
		width: 100%;
		min-height: 40px;
		background: #FFFFFF;
		border: 1px solid #EEEEEE;
		opacity: 1;
		.list_item{
			height: 60px;
			line-height: 60px;
			font-size: 9px;
			border-bottom: 1px dashed  #EEEEEE;
		}
	}
}
</style>